* {
	box-sizing: border-box;
}
body {
	color: #555555;
	font-family: "Open Sans", sans-serif;
	font-size: 16px;
	font-weight: 300;
}
p {
	margin: 1em 0;
}
p:first-child {
	margin-top: 0;
}
p:last-child {
	margin-bottom: 0;
}
a {
	color: #1da7e7;
	text-decoration: none;
}
a:hover,
a:focus {
	text-decoration: underline;
}
@media only screen and (max-width: 450px) {
	body {
		font-size: 15px;
	}
}
